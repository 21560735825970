<template>
  <v-row
    class="pa-0 ma-0 mb-3 app-card app-card-content align-center"
    @click="viewUser"
  >
    <v-col class="d-flex flex-column" cols="4">
      <div class="d-flex align-center">
        <v-avatar class="mr-4" width="30">
          <v-img
            aspect-ratio="1"
            cover
            :lazy-src="require(`@/assets/avatars/user.svg`)"
            :src="userAvatarImage"
          />
        </v-avatar>
        <div class="d-flex align-center name-holder" style="width: 100%;">
          <v-skeleton-loader
            v-if="isLoading"
            type="card-heading"
            style="flex-grow: 1"
          />
          <s-link
            weight="medium"
            color="grayTextAlt"
            :to="{ name: 'appDetails', params: { id } }"
            v-else
          >
            {{ user ? user.bio.name : id }}
          </s-link>
          <copy-to-clipboard
            :data="id"
            tooltip-text="copy id"
          />
        </div>
      </div>
    </v-col>
    <v-col class="d-flex flex-column" cols="4">
      <v-skeleton-loader
        v-if="isLoading"
        type="card-heading"
      />
      <div v-else>
        {{ user?.username }}
      </div>
    </v-col>
    <v-col class="d-flex flex-column" cols="3">
      <v-skeleton-loader
        v-if="isLoading"
        type="card-heading"
      />
      <div v-else>
        {{ user?.created_at ? getHumanReadableDateShort(user.created_at) : "-" }}
      </div>
    </v-col>
    <v-col class="action_group d-flex align-center flex-row-reverse" cols="1">
      <s-icon name="arrow-circle-right" class="mr-2" />
    </v-col>
  </v-row>
</template>

<script>
import { UserMixin } from '@/components/cards/user/user-mixin.js'
export default {
  name: 'UserCard',
  mixins: [UserMixin],
}
</script>

<style lang="scss" scoped>
:deep {
  .v-skeleton-loader__card-heading {
    background: none !important;
  }

  .v-skeleton-loader__heading {
    margin: 0;
    height: 20px;
    width: 60%;
  }

  .name-holder {
    .v-skeleton-loader__heading {
      width: 100%;
    }
  }

  .v-image__image--preload {
    filter: none;
  }
}
</style>
